import React from "react"
import LoginLayout from "../components/layouts/loginLayout"

const NotFoundPage = () => (
  <LoginLayout>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </LoginLayout>
)

export default NotFoundPage
